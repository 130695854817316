<template>
  <div>
    <div class="calendar">
      <div class="calendar__header">
        <CustomHeader
          :color="headerStyles.color"
          :background="headerStyles.background"
          :borderColor="headerStyles.borderColor"
          :icon="headerIcon"
          text="Calendario"
        />
      </div>
      <div class="calendar__content">
        <div class="main-container is-centered">
          <div class="next-games-container-desktop">
            <!-- League rounds -->
            <template v-if="!loadingFlag">
              <div
                :class="
                  type === 'only'
                    ? 'next-games-grid-component'
                    : type === 'onlycomponent'
                    ? 'next-games-grid-component-only'
                    : 'next-games-grid'
                "
              >
                <div class="journey bg-gray" style="line-height: 40px;">
                  {{ displayWidth > 675 ? 'JORNADA' : 'J' }}
                </div>
                <div class="rounds-controller">
                  <div class="rounds-scroll-button left-button is-clickable" @click="onClickScrollLeft">
                    <img src="/icons/icn-next-grey.svg" alt="previous" />
                  </div>
                  <div ref="roundsContainer" class="next-games-rounds-desktop">
                    <div
                      v-for="n in Object.keys(fullTournamentSchedule)"
                      class="round-item-desktop is-clickable btn-jornada"
                      :id="`si${n}`"
                      :style="n == selectedRound ? colorItemScroll : ''"
                      :key="n"
                      @click="selectedRound = n"
                    >
                      {{ n }}
                    </div>
                  </div>
                  <div class="rounds-scroll-button is-clickable" @click="onClickScrollRight">
                    <img src="/icons/icn-next-grey.svg" alt="next" />
                  </div>
                </div>
                <div class="round-schedule-container">
                  <div
                    class="games__container"
                    ref="gamesContainer"
                    :style="gridTemplateColumns"
                    :class="{ fallback: gamesContainerWidth === 0 }"
                  >
                    <template v-if="!isFull || isLanding || (gamesInRoundDate.length > 3 && displayWidth < 699)">
                      <template v-for="(game, i) in gamesToShow.slice(0, 3)">
                        <CalendarMatch
                          :key="i + '-' + game.game_id"
                          :game="game"
                          :predictions="predictions"
                          :color="headerStyles.color2"
                          :background="headerStyles.background"
                          :backgroundLive="headerStyles.backgroundLive"
                          :team-left="game.home_team"
                          :team-right="game.visiting_team"
                          :time="game.schedule.substring(0, 5)"
                          :date="new Date(game.date)"
                          :grid-num="gridExist"
                          :is-game-live="!!game.is_live"
                          :game-status="game.game_status"
                          :score="game.score"
                          :percentages="getPercentages(game)"
                          :home-goals="getGoalsStats(game, 'home')"
                          :visiting-goals="getGoalsStats(game, 'visiting')"
                          :probabilities="getProbabilities(game)"
                          :is-landing="isLanding"
                          @action="onGameClicked(game)"
                        />
                      </template>
                      <template v-if="showComplete">
                        <template v-for="(game, i) in gamesToShow.slice(3)">
                          <CalendarMatch
                            :game="game"
                            :key="i + '-' + game.game_id"
                            :color="headerStyles.color2"
                            :background="headerStyles.background"
                            :backgroundLive="headerStyles.backgroundLive"
                            :team-left="game.home_team"
                            :grid-num="gridExist"
                            :predictions="predictions"
                            :team-right="game.visiting_team"
                            :time="game.schedule.substring(0, 5)"
                            :date="new Date(game.date)"
                            :is-game-live="!!game.is_live"
                            :game-status="game.game_status"
                            :score="game.score"
                            :percentages="getPercentages(game)"
                            :home-goals="getGoalsStats(game, 'home')"
                            :visiting-goals="getGoalsStats(game, 'visiting')"
                            :probabilities="getProbabilities(game)"
                            :is-landing="isLanding"
                            @action="onGameClicked(game)"
                          />
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <template v-for="(game, i) in gamesToShow">
                        <CalendarMatch
                          :key="i + '-' + game.game_id"
                          :game="game"
                          :color="headerStyles.color2"
                          :background="headerStyles.background"
                          :backgroundLive="headerStyles.backgroundLive"
                          :team-left="game.home_team"
                          :predictions="predictions"
                          :team-right="game.visiting_team"
                          :grid-num="gridExist"
                          :time="game.schedule.substring(0, 5)"
                          :date="new Date(game.date)"
                          :is-game-live="!!game.is_live"
                          :game-status="game.game_status"
                          :score="game.score"
                          :percentages="getPercentages(game)"
                          :home-goals="getGoalsStats(game, 'home')"
                          :visiting-goals="getGoalsStats(game, 'visiting')"
                          :probabilities="getProbabilities(game)"
                          @action="onGameClicked(game)"
                        />
                      </template>
                    </template>
                  </div>
                  <button v-if="isLanding" role="button" class="calendar-landing__btn" @click="onClickSeeComplete">
                    Ver calendario completo
                  </button>
                  <button
                    v-else-if="!isFull || (gamesInRoundDate.length > 3 && displayWidth < 699)"
                    class="calendar__btn"
                    @click="showComplete = !showComplete"
                  >
                    {{ showComplete ? 'Ver menos' : 'Ver completo' }}
                  </button>
                </div>

                <footer v-if="!isLanding" class="calendar__footer">
                  <ExternalFooter />
                </footer>
              </div>
            </template>
            <template v-else>
              <div
                :class="
                  type === 'only'
                    ? 'calendar-item-skeleton-component'
                    : type === 'onlycomponent'
                    ? 'calendar-item-skeleton-only'
                    : 'calendar-item-skeleton'
                "
              ></div>
              <footer v-if="!isLanding" class="calendar__footer">
                <ExternalFooter />
              </footer>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { connectSocket } from '@/utils/socketConnection';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { ceilMod } from '@/utils/maths.js';
import axios from 'axios';
let roundScheduleContainer = null;
let nextGamesRoundsDesktop = null;
let intersectionObserverDesktop = null;
let observerEntriesDesktop = new Map();
let calendarResizeObserver = null;

import { clickBtnPartido } from '@/utils/analytics';

const seasonType1Abbreviations = {
  PO1: 'CFI',
  PO2: 'CFV',
  PO3: 'SFI',
  PO4: 'SFV',
  PO5: 'FI',
  PO6: 'FV',
};

export default {
  name: 'Calendar',
  components: {
    CalendarMatch: () => import('@/components/Sections/Calendar/Match'),
    CustomHeader: () => import('@/components/Elements/CustomizableHeader'),
    ExternalFooter: () => import('@/components/Elements/ExternalFooter'),
  },
  props: {
    routeName: {
      type: String,
      default: 'match-analysis',
    },
    type: {
      type: String,
      default: 'only',
    },
    predictions: {
      type: Boolean,
      default: true,
    },
    custom: {
      type: Object,
      default: null,
    },
    /**
     * Seasons objects to fetch games
     * {Array<{id: number, type: number}>} seasons
     */
    seasons: {
      type: Array,
      default: () => [],
    },
    /**
     * Indicates if must await for the seasons prop, or not to get games schedule,
     * if not, tournaments will be fetched to get the seasons
     * {boolean} awaitForSeasons
     */
    awaitForSeasons: {
      type: Boolean,
      default: true,
    },
    isLanding: {
      type: Boolean,
      default: false,
    },
    isGreenHeaderColor: {
      type: Boolean,
      default: false,
    },
    isFull: {
      type: Boolean,
      default: false,
    },
    isWidget: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingFlag: true,
      roundSelectedStyle: {
        'background-color': '#CBEE6E',
      },
      fullTournamentSchedule: {},
      selectedRound: '1',
      gamesContainerWidth: 0,
      tournamentsUrl: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
      gamesUrl: 'https://qyyibs1w0d.execute-api.us-west-2.amazonaws.com/prod/calendar/gamesBySeason?',
      lastSeasonsIds: [],
      showComplete: false,
      allEntries: [],
      isShortVersion: true,
    };
  },
  async created() {
    try {
      if (!this.awaitForSeasons) {
        const tournaments = await this.fetchTournaments(3);
        const mxLeague = tournaments.find(tournament => tournament._id === 1);
        const regularSeason = mxLeague.seasons[0];
        const seasons = [{ id: regularSeason.id, type: regularSeason.type }];
        if (mxLeague && mxLeague.tournament) {
          this.$emit('tournament-selected', mxLeague.tournament);
        }
        if (regularSeason.related_seasons && regularSeason.related_seasons.length) {
          seasons.push(
            ...regularSeason.related_seasons.map(relatedSeason => ({ id: relatedSeason.id, type: relatedSeason.type })),
          );
        }
        await this.createFullTournamentScheme(seasons, false);
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  beforeDestroy() {
    roundScheduleContainer = null;
    nextGamesRoundsDesktop = null;
    intersectionObserverDesktop = null;
    observerEntriesDesktop = new Map();
    if (calendarResizeObserver) {
      calendarResizeObserver.unobserve(this.$refs.gamesContainer);
    }
  },
  watch: {
    seasons: {
      immediate: true,
      deep: true,
      handler: function(newValue) {
        if (newValue && newValue.length) {
          this.lastSeasonsIds = newValue.map(season => season.id);
          this.createFullTournamentScheme(newValue, true);
        }
      },
    },
    selectedRound() {
      if (!roundScheduleContainer) {
        roundScheduleContainer = document.querySelector('.round-schedule-container');
      } else {
        roundScheduleContainer.scrollTop = 0;
      }
    },
    /**
     * {string} newValue - round name displayed
     */
    closestRound(newValue) {
      this.selectedRound = newValue;
    },
    getPremiumAccount(value) {
      if (this.getPremiumAccount && value) connectSocket();
    },
    displayWidth() {
      this.checkHiddenSelectedRound();
    },
    loadingFlag: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          setTimeout(() => {
            this.setUpResizeObserver();
          }, 100);
        }
      },
    },
  },
  computed: {
    ...mapState({ displayWidth: 'displayWidth' }),
    ...mapState('leagueGeneral', ['numberSeasonIdSelected']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    gamesToShow() {
      let gamesToShow = [];
      if (this.gamesInRoundDate && this.gamesInRoundDate.length) {
        if (this.isShortVersion) {
          if (this.gamesInRoundDate[0].game_status === 4 || this.gamesInRoundDate[0].is_live) {
            gamesToShow = this.gamesInRoundDate.slice(0, 3);
          } else {
            let gamesToShowAux = [];
            const availablePicks = {
              post: [],
              live: [],
              pre: [],
            };
            this.gamesInRoundDate.forEach(game => {
              if (game.is_live) {
                availablePicks.live.push(game);
              } else if (game.game_status === 4) {
                availablePicks.pre.push(game);
              } else {
                availablePicks.post.push(game);
              }
            });
            availablePicks.post.reverse();
            if (availablePicks.post.length) {
              const postGames = 3 - (availablePicks.live.length + availablePicks.pre.length);
              gamesToShowAux = [
                ...(gamesToShow = availablePicks.post.slice(0, postGames > 1 ? postGames : 1)),
                ...availablePicks.live,
                ...availablePicks.pre,
              ];
              gamesToShow = gamesToShowAux.slice(0, 3);
            } else {
              gamesToShowAux = [...availablePicks.live, ...availablePicks.pre];
              gamesToShow = gamesToShowAux.slice(0, 3);
            }
          }
        } else {
          gamesToShow = this.gamesInRoundDate;
        }
      }
      return gamesToShow;
    },
    headerIcon() {
      if (this.$route.query.logo) {
        return this.$route.query.logo;
      } else if (this.custom != null && Object.keys(this.custom).length) {
        return this.custom.logo;
      }
      return this.isGreenHeaderColor
        ? 'http://golstatsimages.blob.core.windows.net/content/escudo_golstats.png'
        : 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png';
    },
    headerStyles() {
      if (this.custom != null) {
        if (Object.keys(this.custom).length) {
          return {
            color2: this.custom.headerColor2,
            color: this.custom.headerColor,
            background: this.custom.headerBackground,
            borderColor: this.custom.headerBorderColor,
            backgroundLive: this.custom.header2Background,
          };
        } else {
          return {
            color: this.$route.query.color || '#fff',
            background: this.$route.query.background || '#132839',
          };
        }
      } else {
        return {
          color: this.$route.query.color || this.isGreenHeaderColor ? '#cbee6e' : '#fff',
          background: this.$route.query.background || '#132839',
        };
      }
    },
    colorItemScroll() {
      if (this.custom != null) {
        if (Object.keys(this.custom).length) {
          return {
            background: '#CBEE6E',
          };
        } else {
          return {
            background: '#CBEE6E',
          };
        }
      } else {
        return {
          background: '#CBEE6E',
        };
      }
    },
    gridTemplateColumns() {
      if (this.gamesContainerWidth > 0) {
        const templateColumns =
          this.isLanding || this.gamesContainerWidth < 681
            ? '1fr'
            : this.gamesContainerWidth < 941
            ? '1fr 1fr'
            : this.gamesContainerWidth < 1201
            ? 'repeat(3, 1fr)'
            : 'repeat(4, 1fr)';
        return { 'grid-template-columns': templateColumns };
      }
      return {};
    },
    gridExist() {
      if (this.gamesContainerWidth > 0) {
        const valueGrid =
          this.gamesContainerWidth < 681
            ? 1
            : this.gamesContainerWidth < 941
            ? 2
            : this.gamesContainerWidth < 1201
            ? 3
            : this.gamesContainerWidth < 1510
            ? 4
            : 5;
        return valueGrid;
      }
      return 0;
    },
    closestRound() {
      const gameDuration = 110 * 60 * 1000;
      const roundsAndEndOfLastGame = Object.entries(
        Object.entries(this.fullTournamentSchedule).reduce((accumulator, [k, v]) => {
          accumulator[k] = [];
          v.forEach(day => {
            day.games.forEach(game => {
              const endOfGame = new Date(game.date_time_utc).getTime() + gameDuration;
              accumulator[k].push(endOfGame);
            });
          });
          return accumulator;
        }, {}),
      );
      if (!roundsAndEndOfLastGame.length) {
        return '1';
      }
      const currentTime = new Date().getTime();
      const closestRound = roundsAndEndOfLastGame.reduce(
        (accumulator, [k, v]) => {
          let timeDifferenceGlobal = null;
          v.forEach(endOfGame => {
            const timeDifference = endOfGame - currentTime;
            if (timeDifference >= 0 && (timeDifferenceGlobal === null || timeDifference < timeDifferenceGlobal)) {
              timeDifferenceGlobal = timeDifference;
            }
          });
          if (
            timeDifferenceGlobal &&
            (accumulator.shortestTime === null || timeDifferenceGlobal < accumulator.shortestTime)
          ) {
            accumulator.round = k;
            accumulator.shortestTime = timeDifferenceGlobal;
          }
          return accumulator;
        },
        { round: '', shortestTime: null },
      );

      return closestRound.shortestTime !== null
        ? closestRound.round
        : roundsAndEndOfLastGame[roundsAndEndOfLastGame.length - 1][0];
    },
    gamesInRoundDate() {
      const tournamentScheduleSelected = this.fullTournamentSchedule[this.selectedRound];
      let data = [];
      if (!tournamentScheduleSelected) {
        return [];
      }
      tournamentScheduleSelected.forEach(roundDate => {
        data = [...data, ...roundDate.games.filter(game => game.home_team && game.visiting_team)];
      });
      return data;
    },
  },
  methods: {
    ...mapMutations(['setIsSectionLoading']),
    ...mapMutations('nextGeneral', ['prepareCleanAllStatistics']),
    ...mapMutations('leagueGeneral', ['updateSelectedRound']),
    ...mapActions('routes', ['goToGame']),
    /**
     * @author Alejandro
     * @param {Array<{id: Number, type: Number}>} seasons - Seasons objects
     * id: season id
     * type: season type, values can be 0 for regular and 1 for playoffs
     * @param {boolean} applySeasonsValidation
     * @return {Promise<void>}
     */
    async createFullTournamentScheme(seasons /*, applySeasonsValidation*/) {
      try {
        this.fullTournamentSchedule = {};
        let regularSeasonId = 0;
        const objectRegularSeason = seasons.find(season => season.id === this.numberSeasonIdSelected);
        if (objectRegularSeason) {
          if (this.getPremiumAccount) connectSocket();
          await this.prepareCleanAllStatistics();
          const { seasonsAndGames, seasonsAndTeams } = await this.fetchSeasonsGamesAndTeams(
            [
              objectRegularSeason.id,
              ...objectRegularSeason.related_seasons.map(season => {
                return season.id;
              }),
            ],
            3,
          );
          // if (applySeasonsValidation && this.areSeasonsDifferent(seasonsAndGames)) return;
          seasonsAndGames.forEach(seasonAndGames => {
            seasonAndGames.type = seasonAndGames.season_type;
            seasonAndGames.games.forEach(game => {
              game.season_id = seasonAndGames.season_id;
            });
            if (seasonAndGames.type === 0) {
              regularSeasonId = seasonAndGames.season_id;
            }
          });
          const tournamentGames = {
            seasonsGames: seasonsAndGames,
          };
          const { teams } = seasonsAndTeams.find(seasonAndTeams => seasonAndTeams.season_id === regularSeasonId);
          const seasonsSchedules =
            tournamentGames && tournamentGames.seasonsGames
              ? tournamentGames.seasonsGames.map(seasonAndGames => {
                  const schedule = seasonAndGames.games.reduce((accumulator, game) => {
                    const gameTmp = { ...game };
                    gameTmp.home_team = teams.find(team => team.team_id === game.home_team);
                    gameTmp.visiting_team = teams.find(team => team.team_id === game.visiting_team);
                    if (!accumulator[game.matchday_abbreviation]) {
                      accumulator[game.matchday_abbreviation] = {
                        [game.game_status === 3 ? 'canceled' : game.date]: [gameTmp],
                      };
                    } else if (
                      !accumulator[game.matchday_abbreviation][game.game_status === 3 ? 'canceled' : game.date]
                    ) {
                      accumulator[game.matchday_abbreviation][game.game_status === 3 ? 'canceled' : game.date] = [
                        gameTmp,
                      ];
                    } else {
                      accumulator[game.matchday_abbreviation][game.game_status === 3 ? 'canceled' : game.date].push(
                        gameTmp,
                      );
                    }
                    return accumulator;
                  }, {});
                  for (let [k, v] of Object.entries(schedule)) {
                    schedule[k] = Object.entries(v).map(([k2, v2]) => {
                      return {
                        date: k2,
                        games: v2,
                      };
                    });
                    schedule[k].sort((a, b) => {
                      return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
                    });
                  }
                  return {
                    type: seasonAndGames.type,
                    weight: seasonAndGames.type === 0 ? 0 : seasonAndGames.type === 2 ? 1 : 2,
                    schedule,
                  };
                })
              : [];
          seasonsSchedules.sort((a, b) => a.weight - b.weight);
          this.fullTournamentSchedule = seasonsSchedules.reduce((accumulator, seasonSchedule) => {
            for (const [k, v] of Object.entries(seasonSchedule.schedule)) {
              if (seasonSchedule.type === 0) {
                accumulator[Number(k.replace('M', '')).toString()] = v;
              } else if (seasonSchedule.type === 2) {
                accumulator[`R${Number(k.replace('PO', ''))}`] = v;
              } else if (seasonSchedule.type === 1) {
                accumulator[seasonType1Abbreviations[k]] = v;
              }
            }
            return accumulator;
          }, {});
          setTimeout(() => {
            this.setUpIntersectionObserver();
          }, 100);
        }
      } catch (e) {
        throw new Error(e);
      } finally {
        this.loadingFlag = false;
      }
    },
    areSeasonsDifferent(seasonAndGames) {
      for (const season of seasonAndGames) {
        if (!this.lastSeasonsIds.includes(season.season_id)) {
          return true;
        }
      }
      return false;
    },
    setUpResizeObserver() {
      if (window.ResizeObserver) {
        calendarResizeObserver = new ResizeObserver(() => {
          this.gamesContainerWidth = this.$refs.gamesContainer.clientWidth;
        });
        calendarResizeObserver.observe(this.$refs.gamesContainer);
      } else {
        throw new Error('Resize observer not supported!');
      }
    },
    /**
     * @author Alejandro Martinez
     * @param {number} retries
     * @return {Promise<any|undefined>}
     */
    async fetchTournaments(retries) {
      try {
        const { data } = await axios.get(this.tournamentsUrl);
        return data;
      } catch (e) {
        if (retries > 0) {
          return this.fetchTournaments(retries - 1);
        }
        throw new Error(e);
      }
    },
    /**
     * @author Alejandro Martinez
     * @param {Array<number>} seasons - seasons ids
     * @param {number} retries - Times to retry the request
     * @param {String} createdUrl - Url to make the request
     * @return {Promise<{seasonsAndGames: *, seasonsAndTeams: *}|*|undefined>}
     */
    async fetchSeasonsGamesAndTeams(seasons, retries, createdUrl = '') {
      let gamesUrl = createdUrl;
      try {
        if (!gamesUrl) {
          gamesUrl = this.gamesUrl;
          seasons.forEach((seasonId, index) => {
            const prefix = index === 0 ? '' : '&';
            gamesUrl += `${prefix}seasons=${seasonId}`;
          });
        }
        const {
          data: { data, teams },
        } = await axios.get(gamesUrl);
        return { seasonsAndGames: data, seasonsAndTeams: teams };
      } catch (e) {
        if (retries > 0) {
          return this.fetchSeasonsGamesAndTeams(seasons, retries - 1, gamesUrl);
        }
        throw new Error(e);
      }
    },
    setUpIntersectionObserver() {
      nextGamesRoundsDesktop = document.querySelector('.next-games-rounds-desktop');
      const scrollItems = document.querySelectorAll('.round-item-desktop');
      if (scrollItems.length === 0) return;
      // Creation of an intersection observer
      // see https://developer.mozilla.org/es/docs/Web/API/Intersection_Observer_API
      observerEntriesDesktop.clear();
      intersectionObserverDesktop = new IntersectionObserver(
        entries => {
          if (this.allEntries.length === 0) {
            this.allEntries = entries;
            this.checkHiddenSelectedRound();
          }
          entries.forEach(entry => {
            observerEntriesDesktop.set(entry.target.id, entry);
          });
        },
        {
          root: nextGamesRoundsDesktop,
          threshold: 1,
        },
      );
      scrollItems.forEach(scrollItem => {
        intersectionObserverDesktop.observe(scrollItem);
      });
    },
    checkHiddenSelectedRound() {
      const entry = this.allEntries.find(entry => entry.target.id === `si${this.selectedRound}`);
      if (entry && this.$refs.roundsContainer) {
        const containerRect = this.$refs.roundsContainer.getBoundingClientRect();
        const divRect = entry.target.getBoundingClientRect();
        if (containerRect.width < divRect.x) {
          this.$refs.roundsContainer.scrollLeft = divRect.x - entry.rootBounds.x;
        }
      }
    },
    onClickScrollRight() {
      let startRatio = 0;
      for (let entry of observerEntriesDesktop.values()) {
        // When this conditional compares to true, means that current entry
        // is not fully visible after another that is visible, so this entry's
        // x position is where we need scroll to
        if (startRatio === 1 && entry.intersectionRatio < 1) {
          nextGamesRoundsDesktop.scrollTo({
            // top: 0,
            left: entry.target.offsetLeft - entry.rootBounds.x,
            behavior: 'smooth',
          });
          break;
        }
        startRatio = entry.intersectionRatio;
      }
    },
    onClickScrollLeft() {
      let entryAux = null;
      for (let entry of observerEntriesDesktop.values()) {
        // When this conditional compares to true, means that current entry
        // is fully visible after another that is not fully visible, so the partially visible
        // entry's x position is where we need scroll to
        if (entryAux && entryAux.intersectionRatio < 1 && entry.intersectionRatio === 1) {
          const left = entryAux.target.offsetLeft - entry.rootBounds.x;
          nextGamesRoundsDesktop.scrollTo({
            // top: 0,
            left: left - entryAux.rootBounds.width + entryAux.boundingClientRect.width,
            behavior: 'smooth',
          });
          break;
        }
        entryAux = entry;
      }
    },
    onGameClicked(game) {
      clickBtnPartido(game.game_id, this.getPremiumAccount, this.getIsGolstats);
      if (!this.predictions) {
        this.goToGame({
          name: this.routeName,
          gameId: game.game_id,
          currentSeasonId: game.season_id,
          hideHeader: 1,
          page: 'calendar',
          backRoute: this.$route.name,
        });
      } else {
        this.goToGame({
          name: this.routeName,
          gameId: game.game_id,
          currentSeasonId: game.season_id,
          hideHeader: 1,
          backRoute: this.$route.name,
        });
      }
    },
    getPercentages(game) {
      return [game.home_team_probability, game.draw_probability, game.visiting_team_probability];
    },
    getGoalsStats(game, teamType) {
      return [game[`${teamType}_team_goals`], ceilMod(game[`${teamType}_team_xg`], 2)];
    },
    getProbabilities(game) {
      if (game.draw_probability || game.home_team_probability || game.visiting_team_probability) {
        return {
          drawProbability: game.draw_probability,
          homeTeamProbability: game.home_team_probability,
          awayTeamProbability: game.visiting_team_probability,
        };
      }
      return null;
    },
    onClickSeeComplete() {
      this.$router.push({
        name: 'extendedCalendar',
        // query: {
        //   isCup: 0,
        //   seasons: btoa(JSON.stringify(this.seasons)),
        //   team: this.isWidget ? this.$route.params.team : '',
        // },
      });
    },
  },
};
</script>

<style lang="scss">
$game-border-radius: 9px;
.games__container {
  width: 100%;
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 3px;
  padding: 4px;
  height: fit-content;

  &.fallback {
    @media screen and (max-width: 1080px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 940px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 680px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
.calendar-item-skeleton {
  border-radius: 3px;
  width: 100%;
  height: 666px;
  min-height: 666px;
  max-height: 666px;
  @media screen and (max-width: 1080px) {
    min-height: 431.1px;
    max-height: 431.1px;
  }
  background-color: #f5f5f5;
  box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.05);
  box-sizing: content-box;
  display: flex;
  position: relative;
  overflow: hidden;
  font-family: 'Avenir-Regular';
}
.calendar-item-skeleton-only {
  border-radius: 3px;
  width: 100%;
  height: 460px;
  min-height: 460px;
  max-height: 460px;
  @media screen and (max-width: 1080px) {
    min-height: 431.1px;
    max-height: 431.1px;
  }
  background-color: #f5f5f5;
  box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.05);
  box-sizing: content-box;
  display: flex;
  position: relative;
  overflow: hidden;
  font-family: 'Avenir-Regular';
}
.calendar-item-skeleton-component {
  border-radius: 3px;
  width: 100%;
  height: 460px;
  min-height: 460px;
  max-height: 460px;
  @media screen and (max-width: 1080px) {
    min-height: 431.1px;
    max-height: 431.1px;
  }
  background-color: #f5f5f5;
  box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.05);
  box-sizing: content-box;
  display: flex;
  position: relative;
  overflow: hidden;
  font-family: 'Avenir-Regular';
}
.calendar-item-skeleton::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 100%;
  }
}
html > body {
  padding-top: 0 !important;
}

.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}

.calendar {
  margin: 0 0px;

  .bg-gray {
    background-color: #f7faf9;
  }

  &__header {
    width: 100%;
    min-width: 285px;
    max-width: 1268px;
    margin: 0 auto;
  }

  &__footer {
    grid-area: footer;
    width: 100%;
    height: 2rem;
  }

  &__divider {
    background: lightgrey;
    height: 60%;
    width: 1px;
  }

  &__btn {
    cursor: pointer;
    width: 280px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Circular-Std-Medium;
    border-radius: 14.3px;
    border: solid 5.5px rgba($color: #afbfc7, $alpha: 0.3);
    font-size: 21px;
    margin: 20px auto;
    height: 50px;
    white-space: nowrap;
  }

  &__content {
    .main-container {
      display: flex;
      justify-content: center;
      min-width: 285px;
    }

    .next-games-container-desktop {
      width: 100%;
      border: 1px solid #c0c0c0;

      & .journey {
        color: #132839 !important;
        text-align: center;
      }
    }

    .next-games-grid-component {
      height: 100%;
      max-width: 100%;
      //height: 443px;
      min-height: 438px;
      //max-height: 445px;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      //grid-auto-rows: minmax(40px, auto);
      grid-template-areas:
        'journey paginator paginator paginator paginator paginator'
        'content content content content content content'
        'footer footer footer footer footer footer';
      grid-template-rows: min-content auto;

      & .journey {
        grid-area: journey;
        height: 40px;
      }
      & .rounds-controller {
        grid-area: paginator;
        display: flex;
        height: 40px;
        width: 100%;
      }
      & .round-schedule {
        margin: 0.2rem 0.3rem;
      }
      & .round-schedule-container {
        grid-area: content;
        //display: flex;
        //justify-content: center;
        min-height: 369.1px;
        width: 100%;
        margin: 0 auto;
        max-height: 100%;
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .next-games-grid-component-only {
      height: 100%;
      max-width: 100%;
      //height: 443px;
      min-height: 438px;
      //max-height: 445px;
      display: grid;
      @media screen and (max-width: 699px) {
        height: 100%;
        max-height: none;
        min-height: fit-content;
      }
      grid-template-columns: repeat(6, 1fr);
      //grid-auto-rows: minmax(40px, auto);
      grid-template-areas:
        'journey paginator paginator paginator paginator paginator'
        'content content content content content content'
        'footer footer footer footer footer footer';
      grid-template-rows: min-content auto;

      & .journey {
        grid-area: journey;
        height: 40px;
      }
      & .rounds-controller {
        grid-area: paginator;
        display: flex;
        height: 40px;
        width: 100%;
      }
      & .round-schedule {
        margin: 0.2rem 0.3rem;
      }
      & .round-schedule-container {
        grid-area: content;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        min-height: 369.1px;
        @media screen and (max-width: 699px) {
          height: 100%;
          max-height: none;
          min-height: fit-content;
        }
        width: 100%;
        margin: 0 auto;
        max-height: 100%;
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .next-games-grid {
      height: 100%;
      max-width: 100%;
      height: 730px;
      min-height: 730px;
      max-height: 730px;
      @media screen and (max-width: 1080px) {
        min-height: 464.1px;
        max-height: 464.1px;
      }
      @media screen and (max-width: 699px) {
        height: 100%;
        max-height: none;
        min-height: fit-content;
      }
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      //grid-auto-rows: minmax(40px, auto);
      grid-template-areas:
        'journey paginator paginator paginator paginator paginator'
        'content content content content content content'
        'footer footer footer footer footer footer';
      grid-template-rows: min-content auto;

      & .journey {
        grid-area: journey;
        height: 40px;
      }
      & .rounds-controller {
        grid-area: paginator;
        display: flex;
        height: 40px;
        width: 100%;
      }
      & .round-schedule {
        margin: 0.2rem 0.3rem;
      }
      & .round-schedule-container {
        grid-area: content;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        min-height: 614.1px;
        @media screen and (max-width: 1080px) {
          min-height: 389.1px;
        }
        @media screen and (max-width: 699px) {
          height: 100%;
          max-height: none;
          min-height: fit-content;
        }
        width: 100%;
        margin: 0 auto;
        max-height: 100%;
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .rounds-scroll-button {
      height: 40px;
      width: 40px;
      padding-top: 10px;
      flex-shrink: 0;
      text-align: center;
      background-color: #edf4f9;

      &.left-button > img {
        // Chrome
        -webkit-transform: rotate(180deg);
        // Firefox
        -moz-transform: rotate(180deg);
        // Opera
        -o-transform: rotate(180deg);
        // W3C compliant browsers
        transform: rotate(180deg);
      }

      &:active {
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.25);
      }
    }

    .rounds-scroll-button:hover {
      background-color: #dbdbdb;
    }

    .next-games-rounds-desktop {
      min-width: 125px;
      width: 100%;
      flex-shrink: 1;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      /* Hide scroll bar in firefox*/
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .round-item-desktop {
      width: 30px;
      height: 29px;
      margin: 0 6px;
      border-radius: 5px;
      font-size: 13px;
      vertical-align: middle;
      line-height: 32px;
      flex-shrink: 0;
      text-align: center;
      color: #132839 !important;
      &:hover {
        background-color: #f7faf9;
      }
    }
  }

  &-landing__btn {
    height: fit-content;
    cursor: pointer;
    padding: 6px 32px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Avenir-Medium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.28px;
    text-align: center;
    color: #000;
    border-radius: 8px;
    border: solid 2px #e3eaf0;
    margin: 8px auto 10px;
    white-space: nowrap;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
